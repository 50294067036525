import React from 'react';

function TicketBooking({selectSeat, setSelectSeat}) {

    let whatsappText = `https://api.whatsapp.com/send?phone=994502270440&text=Salam.%20M%C9%99n%20yer%20:categoryName,%20s%C4%B1ra%20:row,%20oturacaq%20:label%20v%C9%99%20qiym%C9%99t%20:price%20AZN%20olan%20bileti%20almaq%20isteyir%C9%99m`

    whatsappText = whatsappText
        .replace(':categoryName', selectSeat.category_name)
        .replace(':row', selectSeat.row)
        .replace(':label', selectSeat.label)
        .replace(':price', selectSeat.discount_price || selectSeat.price)

    const handleClose = () => {
        setSelectSeat(false)
        document.querySelector('.ticket-booking')?.classList?.remove('ticket-booking--open');
    }

    return (
        <div className="ticket-booking flex items-center justify-center p-10">
            <div className="lg:w-[300px] w-full bg-white">
                <div className="px-3 py-2 border-b border-gray-200 flex items-center justify-between">
                    <span className="font-bold uppercase">Bilet</span>
                    <button onClick={() => handleClose()} className="text-sm bg-red-500 py-1 px-2 text-white rounded">Ləğv et</button>
                </div>
                <div className="px-3 py-2 space-y-1">
                    {selectSeat.discount_name && (
                        <div className="flex items-center justify-between">
                            <span className="font-bold">Endirim</span>
                            <span>{selectSeat.discount_name}</span>
                        </div>
                    )}
                    <div className="flex items-center justify-between">
                        <span className="font-bold">Yer</span>
                        <span>{selectSeat.category_name}</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <span className="font-bold">Sıra</span>
                        <span>{selectSeat.row}</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <span className="font-bold">Oturacaq</span>
                        <span>{selectSeat.label}</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <span className="font-bold">Qiymət</span>
                        <span>
                            {selectSeat.discount_price > 0 && (
                                <span className="space-x-1">
                                    <span className="font-bold">{selectSeat.discount_price}AZN</span>
                                    <del className="text-sm">{selectSeat.price}AZN</del>
                                </span>
                            )}
                            {!selectSeat.discount_price && (
                                <span>{selectSeat.price}AZN</span>
                            )}
                        </span>
                    </div>
                </div>
                <div className="px-3 py-2">
                    <a target={'_blank'} className="flex bg-green-500 text-white items-center justify-center py-2 rounded" href={whatsappText}>Bileti satın alın</a>
                </div>
            </div>
        </div>
    );
}

export default TicketBooking;
