import React, {useEffect} from 'react';
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from 'react-tooltip'

function HallComponent({data, selectSeat, setSelectSeat}) {

    const handleSelectSeat = (i) => {
        setSelectSeat(i);
    }

    useEffect(() => {
        if (selectSeat) {
            document.querySelector('.ticket-booking')?.classList?.add('ticket-booking--open');
        }
    }, [selectSeat])

    return (
        <div className="border border-gray-200 relative">
            <img src="./hall-bg.svg" alt=""/>
            <div className="absolute lg:top-44 top-12 pt-2 left-24 pl-2 right-32">
                <div className="lg:py-3 py-1">
                    <h5 className="text-center font-bold lg:text-xl text-xs uppercase">Parter</h5>
                </div>
                <div>
                    {Object?.values(data?.parter)?.map((items, index) => (
                        <div className="flex justify-center lg:space-x-2 space-x-[2px]" key={index}>
                            <div className="w-5">
                                <span className="seat-row">
                                    {index+1}
                                </span>
                            </div>
                            <div className="">
                                <div className="seat-buttons">
                                    {items.sort((a, b) => b.seat - a.seat).map((i, itemIndex) => {
                                        return (
                                            <button
                                                disabled={i.sold}
                                                data-tooltip-content={(i.discount_price || i.price) + ' AZN'} key={itemIndex} onClick={() => handleSelectSeat(i)} className={`my-anchor-element ${selectSeat.id === i.id ? '!bg-blue-500 !text-white' : ''} seat-button`}>
                                                {i.label}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="w-5">
                                <span className="seat-row">
                                    {index+1}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="absolute lg:bottom-44 bottom-12 pt-2 left-28 pl-2 right-32">
                <div className="py-3">
                    <h5 className="text-center font-bold lg:text-xl text-xs uppercase">Amfiteatr</h5>
                </div>
                <div>
                    {Object?.values(data?.amfiteatr)?.map((items, index) => (
                        <div className="flex justify-center lg:space-x-2 space-x-[2px]" key={index}>
                            <div className="w-5">
                                <span className="seat-row">
                                    {index+1}
                                </span>
                            </div>
                            <div className="">
                                <div key={index} className="seat-buttons">
                                    {items.sort((a, b) => b.seat - a.seat).map((i, itemIndex) => {
                                        return (
                                            <button
                                                disabled={i.sold}
                                                data-tooltip-content={(i.discount_price || i.price) + ' AZN'} key={itemIndex} onClick={() => handleSelectSeat(i)} className={`my-anchor-element ${selectSeat.id === i.id ? '!bg-blue-500 !text-white' : ''} seat-button`}>
                                                {i.label}
                                            </button>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="w-5">
                                <span className="seat-row">
                                    {index+1}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Tooltip anchorSelect=".my-anchor-element" />
        </div>
    );
}

export default HallComponent;
