import React, {useEffect, useState} from 'react';
import Header from "./components/Header";
import HallComponent from "./components/HallComponent";
import TicketBooking from "./components/TicketBooking";
import axios from "axios";

function Loading ({children, loading}) {
    return (
        <>
            {loading && (
                <div className="fixed inset-0 bg-white flex items-center justify-center">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{margin: 'auto', background: 'rgba(255, 255, 255, 0)', display: 'block', shapeRendering: 'auto'}} width="60px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx={50} cy={50} fill="none" stroke="#e15b64" strokeWidth={5} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
                            </circle>
                        </svg>
                    </div>
                </div>
            )}
            {!loading && children}
        </>
    )
}

function App(props) {

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        parter: [],
        loja: [],
        amfiteatr: [],
    });

    const [selectSeat, setSelectSeat] = useState(false);

    const fetchSeats = async () => {
        setLoading(true);
        const res = await axios.get(process.env.REACT_APP_API_URL + '/api/front/seats?discount=true');
        if (res.status === 200) {
            setData(res.data);
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchSeats();
    }, [])

    return (
        <Loading loading={loading}>
            <div lang={'az'}>
                <div className="lg:w-[1200px] pb-14 mx-auto overflow-x-hidden">
                    <Header/>
                    <main className="">
                        <div className="col-span-9">
                            <HallComponent data={data} setSelectSeat={setSelectSeat} selectSeat={selectSeat}/>
                        </div>
                        <TicketBooking selectSeat={selectSeat} setSelectSeat={setSelectSeat}/>
                    </main>
                </div>
            </div>
        </Loading>
    );
}

export default App;
