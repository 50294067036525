import React from 'react';
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaPhoneAlt} from "react-icons/fa";

function Header(props) {
    return (
        <header className="bg-white shadow flex mb-3 items-center justify-between">
            <figure>
                <img src="./wallpaper.webp" alt=""/>
            </figure>
        </header>
    );
}

export default Header;
